@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');

body {
	margin: 0px;
	height: 100%;
	font-family: 'Comfortaa', 'Verdana', 'Helvetica', sans-serif;
}

html {
	height: 100%;
}

* {
	font-family: 'Comfortaa', 'Verdana', 'Helvetica', sans-serif;
}

body .layout {
	background: none;
	background-color: none;
	height: 100%;
}

.missing-page-root {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 90vh;
	width: 100%;
}

.layout .header {
	background: transparent;
	background-color: rgba(255, 255, 255, 0.954);
	min-height: 82px;
	padding: 0px;
	z-index: 1001;
	border-bottom: 0.3px solid #7b7b7b9a;
	position: sticky;
	position: -webkit-sticky; /* Safari */
	top: 0;
}

.layout .header img,
.layout .footer img {
	object-fit: cover;
}

.layout .footer {
	background: #fff;
	height: 440px;
	padding: 0px;
	border-top: 0.2px solid #7b7b7b2c;
	box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
}

.content {
	box-sizing: border-box;
	width: 100%;
	min-height: 100vh;
	height: 100%;
	background: linear-gradient(169.69deg, #ffffff 0%, #f7efff 99.67%);
}

.content-root-wrapper {
	position: relative;
	min-height: 60vh;
	display: block;
	max-width: 2560px;
	padding-right: 10vw;
	padding-left: 10vw;
	padding-bottom: 110px;
	margin-left: auto;
	margin-right: auto;
}

#user-profile-popover .ant-popover-inner-content {
	padding: 0px;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	border-radius: 1px;
}
input {
	color-scheme: dark;
}

#webrtc {
	position: absolute;
	left: 250px;
	display: none;
}

.container-buttons {
	display: flex;
	justify-content: space-around;
	position: absolute;
	bottom: 10px;
	width: 100%;
}

@media only screen and (max-width: 932px) {
	.layout .footer {
		height: 100%;
	}
}

@media only screen and (max-width: 750px) {
	.content-root-wrapper {
		padding-left: 2vw;
		padding-right: 2vw;
	}
}

#__next {
	min-height: 100%;
}

body {
	overflow: auto !important;
}

.content {
	position: relative;
}

.container-onside-canvas {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	width: 100%;
	padding: 5px;
	top: 0;
}

.container-onside-canvas>* {
	z-index: 5;
}

.container-onside-canvas>div {
	display: flex;
	gap: 5px;
}

.image-icon-menu {
	width: 30px;
	height: auto;
}

.editor-drawer {
	width: 230px;
	background-color: white;
	display: flex;
	flex-direction: column;
	gap: 5px;
	overflow-y: auto;
	border-left: 1px solid rgb(67, 67, 67);
}

.menu-side-drawer {
	width: 250px;
	background-color: #1c1c1c;
	display: flex;
	flex-direction: column;
	gap: 5px;
	overflow-y: auto;
	border-left: 1px solid rgb(67, 67, 67);
}

.menu-root-wrapper {
	display: flex;
	position: absolute;
	z-index: 6;
	height: calc(100vh - 65px);
}

.editor-buttons-wrapper {
	width: 0;
	height: 0;
}

.editor-buttons-wrapper>div {
	margin-left: 20px;
}

.container-sidedrawer {
	position: relative;
	height: calc(100vh - 64px);
	overflow: auto;
	width: 248px;
	right: 0px;
	bottom: 0px;
	z-index: 5;

	background: #fff !important;

	display: none;
}

.container-sidedrawer.active {
	display: block;
}

.container-sidedrawer>button {
	position: absolute;
	right: 0;
}

/* .container-sidedrawer > div {
	display: flex;
	overflow-x: auto;
	width: 100%;
	gap: 5px;
} */

.sidedrawer-element-disabled {
	cursor: default;
	opacity: 50%;
}

.sidedrawer-element {
	height: 120px;
	width: 100%;
	margin-bottom: 16px;
	border-radius: 15px;
	flex-shrink: 0;
}

.sidedrawer-element-enabled {
	cursor: pointer;
}

.sidedrawer-element-selected {
	border: 3px solid #e20073;
}

.container-none-flex {
	position: relative;
	display: none;
}

.container-none-flex.active {
	display: flex;
	overflow-y: auto;
	border-left: 1px solid rgb(67, 67, 67);
}

.menu-side-drawer .close-side-menu-button {
	position: absolute;
	right: 0px;
}

.create-scene-button {
	text-align: right;
	padding: 20px 40px;
}

.edit-scene-modal-button {
	display: flex;
	justify-content: center;
}

.public-scene-auth {
	position: absolute;
	top: 15px;
	right: 15px;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	border: 0.3px solid rgba(255, 255, 255, 0.573);
	border-radius: 25px;
	background: #20202081;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	border: 0.3px solid rgba(255, 255, 255, 0.526);
	border-radius: 25px;
	background: #07070781;
}

.scene-editor-menu {
	overflow-y: auto;
	overflow-x: hidden;
	height: 100%;
	background-color: none !important;
	background: none !important;
}

.container-audio {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	width: 100%;
	border-radius: 12px;
	border: 1px solid #888;
	margin-bottom: 16px !important;
	height: 100px;
}

#side-menu .ant-layout-sider-trigger {
	color: #333;
	border-top: 0.3px solid #7b7b7b9a;
}

* a {
	color: #333;
}
body {
  background-color: #ffffff;
  margin: 0;
  overflow: hidden;
}

#CSS3D,
#canvasID {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#canvasID {
  z-index: 1;
}

#CSS3D {
  background: black;
}

/* these rules are used to make it work with the other home template structure*/
/*
#root button{
  z-index: 1;
}

#webgl{
  z-index: auto;
}
*/

.container-mobil-controls {
  width: 120px;
  height: 120px;
  position: absolute;
  z-index: 3;
  bottom: 4vh;
  right: 3vw;
}

@media only screen and (max-width: 940px) {
  .container-mobil-controls {
    bottom: 15vh;
    right: 4vw;
  }
}

#local-media {
  width: 150px !important;
  height: 75px !important;
  position: absolute;
  z-index: 6;
  bottom: 55px !important;
  left: 10px !important;
  display: none;
}

#local-media > video {
  height: 100% !important;
  object-fit: cover;
  background-color: black;
}

#local-media.hidden {
  display: none;
}

.chakra-button.btn-buy {
  background: radial-gradient(
      96% 96% at 70.4% 31.2%,
      #a74eff 0%,
      rgba(0, 2, 16, 0) 100%
    )
    rgba(103, 0, 205, 0.8) !important;
  border: 1px solid rgba(255, 255, 255, 0.05) !important;
  box-shadow: inset 1px 3px 13px #5c00b7 !important;
  border-radius: 8px !important;
}

.chakra-button.btn-buy:hover {
  background: radial-gradient(
      96% 96% at 70.4% 31.2%,
      #a951ff 0%,
      rgba(0, 2, 16, 0) 100%
    )
    rgb(133, 19, 245) !important;
  box-shadow: inset 1px 3px 13px #5c00b7 !important;
}

.cc-banner.cc-bottom {
  border-top: 0.3px solid #7b7b7b9a;
}

.cc-banner > .cc-message {
  font-family: Comfortaa, Verdana, Helvetica, sans-serif;
  font-size: 14px;
}
.cc-banner > .cc-message > a.cc-link,
.cc-banner.cc-bottom > .cc-message > a.cc-link:visited {
  opacity: 0.8;
  display: inline-block;
  padding: 0 0.4em;
  text-decoration: underline;
  color: #818080;
}
.cc-banner.cc-bottom > .cc-message > a.cc-link:hover {
  color: #1a1a1a;
  text-decoration: none;
  outline: 0;
}

.cc-banner.cc-bottom > .cc-compliance > .cc-btn {
  font-family: Comfortaa, Verdana, Helvetica, sans-serif;
  font-size: 13.3333px;
  white-space: nowrap;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 4px 15px;
  font-weight: normal;
  background: rgb(240, 240, 240);
  border: 1px solid black;
  border-radius: 3px;
  min-width: 75px;
}
.cc-banner.cc-bottom > .cc-compliance > .cc-btn:hover {
  background: rgb(206, 205, 205);
}

.cc-window.cc-banner.cc-type-info.cc-theme-classic.cc-bottom {
  line-height: normal;
}

.quest-ui {
  position: absolute;
  z-index: 5;
  right: 10px;
  padding: 10px;
  top: 70px;
}

.main-menu-mobile-button {
  width: 50px;
  height: 50px;
  margin: 5px;
}

