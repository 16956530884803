#__next {
	min-height: 100%;
}

body {
	overflow: auto !important;
}

.content {
	position: relative;
}

.container-onside-canvas {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	width: 100%;
	padding: 5px;
	top: 0;
}

.container-onside-canvas>* {
	z-index: 5;
}

.container-onside-canvas>div {
	display: flex;
	gap: 5px;
}

.image-icon-menu {
	width: 30px;
	height: auto;
}

.editor-drawer {
	width: 230px;
	background-color: white;
	display: flex;
	flex-direction: column;
	gap: 5px;
	overflow-y: auto;
	border-left: 1px solid rgb(67, 67, 67);
}

.menu-side-drawer {
	width: 250px;
	background-color: #1c1c1c;
	display: flex;
	flex-direction: column;
	gap: 5px;
	overflow-y: auto;
	border-left: 1px solid rgb(67, 67, 67);
}

.menu-root-wrapper {
	display: flex;
	position: absolute;
	z-index: 6;
	height: calc(100vh - 65px);
}

.editor-buttons-wrapper {
	width: 0;
	height: 0;
}

.editor-buttons-wrapper>div {
	margin-left: 20px;
}

.container-sidedrawer {
	position: relative;
	height: calc(100vh - 64px);
	overflow: auto;
	width: 248px;
	right: 0px;
	bottom: 0px;
	z-index: 5;

	background: #fff !important;

	display: none;
}

.container-sidedrawer.active {
	display: block;
}

.container-sidedrawer>button {
	position: absolute;
	right: 0;
}

/* .container-sidedrawer > div {
	display: flex;
	overflow-x: auto;
	width: 100%;
	gap: 5px;
} */

.sidedrawer-element-disabled {
	cursor: default;
	opacity: 50%;
}

.sidedrawer-element {
	height: 120px;
	width: 100%;
	margin-bottom: 16px;
	border-radius: 15px;
	flex-shrink: 0;
}

.sidedrawer-element-enabled {
	cursor: pointer;
}

.sidedrawer-element-selected {
	border: 3px solid #e20073;
}

.container-none-flex {
	position: relative;
	display: none;
}

.container-none-flex.active {
	display: flex;
	overflow-y: auto;
	border-left: 1px solid rgb(67, 67, 67);
}

.menu-side-drawer .close-side-menu-button {
	position: absolute;
	right: 0px;
}

.create-scene-button {
	text-align: right;
	padding: 20px 40px;
}

.edit-scene-modal-button {
	display: flex;
	justify-content: center;
}

.public-scene-auth {
	position: absolute;
	top: 15px;
	right: 15px;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	border: 0.3px solid rgba(255, 255, 255, 0.573);
	border-radius: 25px;
	background: #20202081;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	border: 0.3px solid rgba(255, 255, 255, 0.526);
	border-radius: 25px;
	background: #07070781;
}

.scene-editor-menu {
	overflow-y: auto;
	overflow-x: hidden;
	height: 100%;
	background-color: none !important;
	background: none !important;
}

.container-audio {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	width: 100%;
	border-radius: 12px;
	border: 1px solid #888;
	margin-bottom: 16px !important;
	height: 100px;
}

#side-menu .ant-layout-sider-trigger {
	color: #333;
	border-top: 0.3px solid #7b7b7b9a;
}

* a {
	color: #333;
}